import React from "react";
import { cn } from "../lib/helpers";

import * as styles from "./dropdown.module.css";
import * as commonStyles from "../styles/common.module.css";

// dropdown context for open state
const DropdownContext = React.createContext({
  open: false,
  setOpen: () => {},
});

// dropdown component for wrapping and providing context
export function Dropdown({ children, ...props }) {
  const [open, setOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);

  // click listeners for closing dropdown
  React.useEffect(() => {
    // close dropdown if click outside
    function close(e) {
      if (!dropdownRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    // add or remove event listener
    if (open) {
      window.addEventListener("click", close);
    }
    // cleanup
    return function removeListener() {
      window.removeEventListener("click", close);
    };
  }, [open]); // only run if open state changes

  return (
    <DropdownContext.Provider value={{ open, setOpen }}>
      <div ref={dropdownRef} className="relative mt-2 mr-1">
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

// dropdown button for triggering open
export function DropdownButton({ children, ...props }) {
  const { open, setOpen } = React.useContext(DropdownContext); // get the context

  // to open and close the dropdown
  function toggleOpen() {
    setOpen(!open);
  }

  return <button onClick={toggleOpen}>{children}</button>;
}

// dropdown content for displaying dropdown
export function DropdownContent({ children }) {
  const { open } = React.useContext(DropdownContext); // get the context

  return (
    <div
      className={cn(
        styles.content,
        `absolute z-20  border-gray-300 bg-white overflow-hidden my-1 overflow-y-auto ${
          open ? "shadow-md" : "hidden"
        }`
      )}
    >
      {children}
    </div>
  );
}

// dropdown list for dropdown menus
export function DropdownList({ children, ...props }) {
  const { setOpen } = React.useContext(DropdownContext); // get the context

  return (
    <ul onClick={() => setOpen(false)} className="divide-y divide-gray-200" {...props}>
      {children}
    </ul>
  );
}

// dropdown items for dropdown menus
export function DropdownItem({ children, ...props }) {
  return (
    <li>
      <button
        className={cn(
          styles.button,
          commonStyles.fontReleway,
          commonStyles.colorDarkGray,
          "py-3",
          "px-5",
          "hover:underline"
        )}
        {...props}
      >
        {children}
      </button>
    </li>
  );
}
