// extracted by mini-css-extract-plugin
export var headline = "galleryMain-module--headline--3QBQN";
export var galleryContainer = "galleryMain-module--galleryContainer--3E0aS";
export var promo = "galleryMain-module--promo--bSYnj";
export var carousel = "galleryMain-module--carousel--psAp8";
export var title = "galleryMain-module--title--2XNu_";
export var headerContainer = "galleryMain-module--headerContainer--oBP6E";
export var titleContainer = "galleryMain-module--titleContainer--1EBXr";
export var description = "galleryMain-module--description--3qPbA";
export var tags = "galleryMain-module--tags--1I5o0";
export var item = "galleryMain-module--item--1W7FC";
export var dots = "galleryMain-module--dots--1xUPA";
export var buttonContainer = "galleryMain-module--buttonContainer--2xUQY";
export var button = "galleryMain-module--button--1IfY8";