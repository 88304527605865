import React, { useState } from "react";
import { Carousel } from "@kkx64/react-simple-carousel";
import { Link } from "gatsby";

import Icon from "./icon/icon";
import slugify from "../slugify";
import { cn } from "../lib/helpers";
import * as styles from "./galleryMain.module.css";
import * as commonStyles from "../styles/common.module.css";
import { Dropdown, DropdownButton, DropdownContent, DropdownList, DropdownItem } from "./dropdown";

const isBrowser = typeof window !== "undefined";

export const mapImage = ({ asset: { url: src } = {} }, title) => (
  <img key={`image-${src}`} src={src} alt={title} />
);

const LongText = ({ text, showMore, setShowMore }) => {
  return (
    <>
      {showMore ? text : text.slice(0, 100)}
      {!showMore && (
        <button className={commonStyles.colorAccent} onClick={() => setShowMore(true)}>
          {" ...více"}
        </button>
      )}
    </>
  );
};

export const Description = ({ text }) => {
  const [showMore, setShowMore] = useState(false);
  const isLong = text?.length > 120;

  return (
    <p className={cn(styles.description, commonStyles.fontButler, commonStyles.colorDarkGray)}>
      {isLong ? <LongText text={text} showMore={showMore} setShowMore={setShowMore} /> : text}
    </p>
  );
};

function GalleryMain({ data = [], promo = false }) {
  const defaultShown = 5;
  let z = 0;

  if (isBrowser) {
    const url = new URL(window.location.href);

    z = Number(url.searchParams.get("z"));
  }

  const [shownImages, setShown] = useState(defaultShown + z);

  return (
    <div>
      {!promo && (
        <h1
          className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
        >
          Galerie
        </h1>
      )}
      <div className={promo ? styles.promo : styles.galleryContainer}>
        {data.slice(0, shownImages).map((item) => (
          <div id={item.id} key={`galleryItem_${item.id}`} className={styles.item}>
            <div className={styles.headerContainer}>
              <div className={styles.titleContainer}>
                <Icon type={item?.icon || "akupunktura"} active={true} />
                <h2
                  className={cn(
                    styles.title,
                    commonStyles.fontButlerMedium,
                    commonStyles.colorDarkGray
                  )}
                >
                  {item.title}
                </h2>
              </div>
              <Dropdown>
                <DropdownButton>
                  <div className={styles.dots}>
                    <svg
                      width="40px"
                      height="40px"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M19 16a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3zm0 13a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3zm0-26a3 3 0 0 1-3 3 3 3 0 0 1-3-3 3 3 0 0 1 3-3 3 3 0 0 1 3 3z" />
                    </svg>
                  </div>
                </DropdownButton>
                <DropdownContent>
                  <DropdownList>
                    {item?.categories && item?.categories.length > 0 && (
                      <DropdownItem>
                        <Link to={`/vyhledavani/${slugify(item?.categories[0].title)}`}>
                          <span>{"Více o proceduře"}</span>
                        </Link>
                      </DropdownItem>
                    )}
                    <DropdownItem>
                      <Link to={`/konzultace?${encodeURI(`Galerie - ${item.title}`)}`}>
                        <span>{"Chci se na něco zeptat"}</span>
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <a
                        title="BBClinic Shop - Konzultace"
                        target="_blank"
                        href={
                          item?.shopLink ? item.shopLink : "https://shop.bbclinic.cz/konzultace"
                        }
                      >
                        {"Chci si objednat konzultaci"}
                      </a>
                    </DropdownItem>
                  </DropdownList>
                </DropdownContent>
              </Dropdown>
            </div>
            {item?.images.length > 1 ? (
              <div className={styles.carousel}>
                <Carousel dotsFixed>
                  {item?.images.map((image) => mapImage(image, item.title))}
                </Carousel>
              </div>
            ) : (
              mapImage(item?.images[0], item.title)
            )}
            <Description text={item?.description} />
            <div className={cn(styles.tags, commonStyles.fontReleway, commonStyles.colorDarkGray)}>
              {item?.categories.map((tag, indexT) => {
                const { title } = tag;
                return (
                  <Link key={`procedure_tag_${indexT}`} to={`/vyhledavani/${slugify(title)}`}>
                    <span key={`procedure_tag_${indexT}`}>
                      {title}
                      {indexT !== item?.categories.length - 1 && " — "}
                    </span>
                  </Link>
                );
              })}
            </div>
          </div>
        ))}
        {data.length > shownImages && (
          <div className={styles.buttonContainer}>
            <button
              className={cn(commonStyles.colorAccent, commonStyles.fontReleway, styles.button)}
              onClick={() => {
                z = z + defaultShown;
                const url = new URL(window.location.href);
                url.searchParams.set("z", z);
                window.history.pushState(null, "", url.toString());
                setShown(shownImages + z);
              }}
            >
              Načíst více obrázků
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default GalleryMain;
